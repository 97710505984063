import React from 'react'
import { SRLWrapper } from "simple-react-lightbox";

const options = {
    buttons: {
    //   backgroundColor: "rgba(140, 94, 88, 0.8)",
    //   iconColor: "rgba(241, 191, 152, 0.7)",
      showAutoplayButton: 'false',
      showDownloadButton: 'false',
      showThumbnailsButton: 'false'
    },
    settings: {
    //   overlayColor: "rgba(255, 237, 225, 1)",
      transitionSpeed: 300,
      lightboxTransitionSpeed: 0.1,
      autoplaySpeed: 0,
      transitionTimingFunction: "linear"
    },
    thumbnails: {
      thumbnailsSize: ["100px", "80px"],
      thumbnailsOpacity: 0.4,
      thumbnailsAlignment: 'center',
      thumbnailsPosition: 'bottom'
    },
    caption: {
    //   captionColor: "rgba(241, 191, 152, 1)"
        captionContainerPadding: '5px 10px 5px 10px'
    },
    progressBar: {
      size: "4px",
    //   backgroundColor: "rgba(255, 237, 225, 1)",
    //   fillColor: "#AF9AB2"
    }
  };

export const Figures = (props) => {
    const data = props.data;
    return (
        <SRLWrapper options={options}>
            {props.children}
        </SRLWrapper>
    );
}

export default Figures
