import React, {useState, useRef, useEffect} from 'react';
import { graphql, Link } from 'gatsby';
import { useLocation } from '@reach/router';

import SimpleReactLightbox from 'simple-react-lightbox'

import SEO from '../components/SEO'

import PublicationPage from '../components/PublicationPage'

import '../assets/css/custom.css'

const path = (src, pre) => {
  if (src.includes('http')) {
      return src;
  } else {
      return pre + src;
  }
}

const Template = ({ data }) => {
	// const title = data.markdownRemark.frontmatter.title;
	// const date = data.markdownRemark.frontmatter.date;
  // const html = data.markdownRemark.html;
  
  // state = {
  //   isArticleVisible: false,
  //   timeout: false,
  //   articleTimeout: false,
  // } 

  // const [time, setTime] = useState(0);
  // const [time2, setTime2] = useState(null);
  // const timeRef = useRef(time);
  // timeRef.current = time;

  const [clock, setClock] = useState(false);
  const clockRef = useRef(clock);
  clockRef.current = clock;

  // setTimeout(() => {
  //   console.log(clock)
  //   setClock(true)
  //   console.log(clock)
  // }, 1000)
  useEffect(() => {
    setTimeout(() => {
      setClock(true)
    }, 150) 
  }, [])

  const post = data.mdx;
  const site = data.site.siteMetadata;
  // return <PublicationPage data={post} site={site} />;

  const location = useLocation()
  var linkState = {}
  if (location.key != 'initial') {
      linkState = {section: 'publications'}
  }

  const close = (
    <Link to={'/'} state={linkState} className="close">
      {/* <div className="close"></div> */}
    </Link>
  )

  return (
    <SimpleReactLightbox>
    <div class="body is-article-visible">
    {/* <SEO title={post.frontmatter.title} description={post.excerpt} image={path(post.frontmatter.thumb, post.fields.slug)}/> */}
    <SEO title={post.frontmatter.title} description={post.excerpt} image={post.frontmatter.socialMedia} cardType="summary_large_image"/>
    <div id="wrapper">
    <div id="main" style={{ display: 'flex'}}> 
    <article
          id="publications"
          className={clock ? 'active timeout' : ''} 
          style={{ width: '90vw' }}  
    >
      {/* {close}   */}
      <PublicationPage data={post} site={site}/>

    </article>
    </div>
    <div id="bg"></div>
    </div>
    </div>
    </SimpleReactLightbox>
  );
};

export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        title
        rawDate: date
        date(formatString: "DD MMMM YYYY")

        authors
        authors_url
        authors_affiliation
        affiliation
        year

        extra
        journal
        teaser
        socialMedia
        thumb
        pdf
        supp
        webpage
        bibtex 
        video
        presentation
        poster
        code
        slides
        publisher
      }
      timeToRead
      excerpt
      body
    },
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export default Template;
