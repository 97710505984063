import React from 'react'

import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from "@mdx-js/react"

import {Resources} from './PublicationPage/resources';
import {Figures} from './PublicationPage/figures';
import {VideoGallery} from './PublicationPage/videoGallery';

import "../assets/css/publication_style.css"

import "katex/dist/katex.min.css"


const components = {
    resources: props => <Resources {...props} />,
    h2: props => <h2 id={props.children}  {...props} />,
    p: props => <p style={{textAlign: 'justify', textJustify: 'inter-character', textIndent: '1.5rem', margin: '0 0 0.5rem 0'}} {...props}/>,
    figures: props => <Figures {...props} />,
    videoGallery: props => <VideoGallery {...props} />
};

const PublicationPage = props => {
return (
    <div> 
    {/* <div id="wrapper" className="page"> */} 
    <div id="header">
    <div id="journal">{props.data.frontmatter.journal} - {props.data.frontmatter.year}</div>
    {/* <div id="title" style={{borderBottom: '1px solid gray', paddingBottom: '10px'}}><a href="" class="nounderline">{props.data.frontmatter.title}</a></div>  */}
    <div id="title" style={{borderBottom: '1px solid gray', paddingBottom: '10px'}}>{props.data.frontmatter.title}</div>

    <div id='authors' style={{marginTop: '2ex', fontSize: '1.2em', flexDirection: 'row', flexFlow: 'row wrap', display: 'flex', justifyContent: 'center'}}>
        {props.data.frontmatter.authors.map((data, index) => {
            return (
                // <span className='spanMobile' style={{fontVariant: 'small-caps', fontSize: 'larger', whiteSpace: 'nowrap'}}>
                <span style={{fontVariant: 'small-caps', fontSize: 'larger', whiteSpace: 'nowrap', width: 'fit-content', margin: '0 2ex'}}>
                    {/* <div> */}
                    { props.data.frontmatter.authors_url[index] != '' &&
                        <a href={props.data.frontmatter.authors_url[index]}>{data}</a>
                    }
                    { props.data.frontmatter.authors_url[index] == '' &&
                        <span>{data}</span>
                    }
                    {/* </div> */}
                    {/* {props.data.frontmatter.authors_affiliation[index].map( (d, i) => {
                        <span class="super">{d}</span>
                    })} */}
                    <span class="super" style={{marginLeft: '5px', whiteSpace: 'nowrap'}}>{props.data.frontmatter.authors_affiliation[index]}</span>
                </span>
            )
        })}
    </div>

    <div id='authors' style={{marginTop: '2ex'}}>
        {props.data.frontmatter.affiliation.map((data, index) => {
        return (
            <span className='spanMobile'>
                {/* <span class="super" style={{marginRight: '5px'}}>{index+1}</span> <span>{data}</span> */}
                {data == "Joint first authors" && 
                    <span><span class="super" style={{marginRight: '5px', fontSize: '1'}}>*</span> <span>{data}</span></span>
                }
                {data != "Joint first authors" && 
                    <span><span class="super" style={{marginRight: '5px'}}>{index+1}</span> <span>{data}</span></span>
                }
            </span>
            )
        })}
    </div>

    {/* <div id="teaserFrame">
        <img src={props.data.frontmatter.teaser} alt="Teaser" id="teaser"/> 
    </div> */}

    {/* <ul id="navigation">
        <li><a href="#news">News</a></li>
        <li><a href="#abstract">Abstract</a></li>
        <li><a href="#downloads">Downloads</a></li>
        <li><a href="#bibtex">Bibtex</a></li>
        <li><a href="#links">Links</a></li>
        <li><a href="#related">Related</a></li>
    </ul> */}

    </div>

    
    
    <div id="content">
    {/* <div className="blogpost" dangerouslySetInnerHTML={{ __html: props.data.html }} /> */}
    
    <MDXProvider components={components}>
        <MDXRenderer frontmatter={props.data.frontmatter}>{props.data.body}</MDXRenderer>
    </MDXProvider>
    </div>
    </div>
)
}

// PublicationPage.propTypes = {
    
// }

export default PublicationPage
