import React from 'react'
import ImageGallery from 'react-image-gallery'
import ReactPlayer from 'react-player'

// import "react-image-gallery/styles/css/image-gallery.css"
import "react-image-gallery/styles/scss/image-gallery.scss"
import "./videoGallery.css"

export class VideoGallery extends React.Component {

    constructor(props) {
        super()

        const src = props.src
        const data = props.data

        var dataState = []
        src.forEach( (val, index) => {
            var res = {}
            res['original'] = data[index]['thumb']
            res['thumbnail'] = data[index]['thumb']
            res['renderItem'] = this._renderVideo.bind(this)
            res['thumbnailLabel'] = data[index]['short']
            res['description'] = data[index]['long']
            res['embedUrl'] = src[index]

            dataState.push(res)
        })

        this.state = {
            data: dataState,
            showVideo: {},
            isMobile: false
        }

        this._updateMobileInfo = this._updateMobileInfo.bind(this)
    }

    componentDidMount () {
        if (window) {
            window.addEventListener("resize", this._updateMobileInfo)
        }
    }

    componentWillUnmount () {
        if (window) {
            window.removeEventListener('resize', this._updateMobileInfo)
        }
    }

    _updateMobileInfo () {
        if (window) {
            const w = window.innerWidth
            this.setState({
                isMobile: w < 770
            })
        }
    }

    _onSlide(index) {
        this._resetVideo();
    }

    _resetVideo() {
        this.setState({showVideo: {}});
    
        if (this.state.showPlayButton) {
          this.setState({showGalleryPlayButton: true});
        }
    
        if (this.state.showFullscreenButton) {
          this.setState({showGalleryFullscreenButton: true});
        }
    }

    _toggleShowVideo(url) {
        this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);
        this.setState({
          showVideo: this.state.showVideo
        });
    
        if (this.state.showVideo[url]) {
          if (this.state.showPlayButton) {
            this.setState({showGalleryPlayButton: false});
          }
    
          if (this.state.showFullscreenButton) {
            this.setState({showGalleryFullscreenButton: false});
          }
        }
    }

    _renderVideo(item) {
        return (
          <div>
            {
              this.state.showVideo[item.embedUrl] ?
                <div className='video-wrapper'>
                    {/* <a
                      className='close-video'
                      onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
                    >
                    </a> */}

                    {/* <iframe
                      width='100%'
                    //   height='100%'
                      src={item.embedUrl}
                      frameBorder='0'
                      allowFullScreen
                    >
                    </iframe> */}
                    
                    <ReactPlayer 
                        url={item.embedUrl}
                        light={false}
                        controls={true}
                        pip={true}
                        playing={true}
                        className='react-player vimeoFrame vimeoXtra'
                        width='100%'
                        height='100%' />
                    
                    {/* <div id='infoi'>
                    </div> */}
                </div>
              :
                <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
                  <div className='play-button'></div>
                  <img className='image-gallery-image' src={item.original} />
                  {
                    item.description &&
                      <span
                        className='image-gallery-description'
                        style={{right: '0', left: 'initial'}}
                      >
                        {item.description}
                      </span>
                  }
                </a>
            }
          </div>
        );
    }

    render() {
        return (
            this.state.data.length !== 0 && <div id='Video' style={{width: '100%'}}>
                <div>
                    <ImageGallery 
                        items={this.state.data}
                        thumbnailPosition={this.state.isMobile ? 'bottom' : 'right'}
                        showPlayButton={false}
                        showFullscreenButton={false}
                        showNav={false}
                        showBullets={false}
                        disableKeyDown={true}
                        useWindowKeyDown={false}
                        onSlide={this._onSlide.bind(this)}
                        additionalClass="app-image-gallery" />
                    </div>
            </div>
        )
    }
}

export default VideoGallery
