import React from 'react'

export const Resources = (props) => {
    var data = JSON.parse(JSON.stringify(props.data))
    var labels = props.labels
    const NAMES = ['pdf', 'supp', 'code', 'video', 'poster', 'presentation', 'slides', 'bibtex']
    const DESCRIPTION = ['Paper', 'Supplemental', 'Code', 'Video', 'Poster', 'Presentation', 'Slides', 'Cite']
    const ICON = ['file-pdf', 'file-pdf', 'code', 'film', 'picture-o', 'file-powerpoint-o', 'file-powerpoint-o', 'quote-right']

    // Deal with specific entries
    if (data['bibtex'] !== '') {
        labels['bibtex'] = ''
        data['bibtex'] = '#Bibtex'
    }

    console.log(data['video'].length)
    if (data['video'].length != 0) {
        labels['video'] = ''
        data['video'] = '#Video'
    } else {
        data['video'] = ''
    }

    return (
       <div className='resources_publication'>
           <h2> Resources </h2>
           {
               NAMES.map( (name, index) => {
                    return (
                        data[name] !== '' && <a className='button_resources' href={data[name]}>
                            <i class={"button_icon fa fa-" + ICON[index]}></i>
                            <span> {DESCRIPTION[index] + ' ' + labels[name]} </span>
                        </a>
                    )
               } )
           }
       </div>
    );
}

export default Resources
